//
// client-side.ts
//

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { format } from "date-fns";
import posthog from "posthog-js";

/**
 * Determines the execution environment of the dashboard based on the hostname.
 *
 * @returns {string} The execution environment, such as "LOCALHOST", "STAGING", "PRODUCTION", or a custom environment name.
 */
export function getExecutionAmbient(): string {
  // Get the current URL from the browser
  const url = new URL(window.location.href);

  // Default to "UNKNOWN" if no matching environment is found
  let ambient = "UNKNOWN";

  // Determine environment based on the hostname
  if (url.hostname.includes("local")) {
    ambient = "LOCALHOST";
  } else if (url.hostname === "staging.sqlitecloud.io") {
    ambient = "STAGING";
  } else if (url.hostname === "dashboard.sqlitecloud.io") {
    ambient = "PRODUCTION";
  } else if (url.hostname.includes("wip")) {
    // Extract and capitalize the custom environment name if "wip" is in the hostname
    ambient = url.hostname.split(".")[0].toUpperCase();
  }

  // Return the determined environment
  return ambient;
}

/**
 * Fetch function used by SWR hooks, designed to throw an error if the request fails.
 *
 * @param {[string, string, string[], string]} params - An array containing:
 *   - url (string): The URL to fetch.
 *   - component (string): The component making the request, used in error messages.
 *   - queryStrings (string[] | undefined): Optional query strings to append to the URL.
 *   - auth (string | undefined): Optional authorization token.
 *
 * @returns {Promise<any>} - The response data or binary content if the request is successful.
 *
 * Function Steps:
 * 1. Appends any provided query strings to the URL.
 * 2. Sets up headers, including an authorization token if provided.
 * 3. Sends a GET request to the specified URL.
 * 4. If redirected, resets PostHog and navigates to the sign-in page.
 * 5. Throws an error if the response is not ok and not redirected, including timestamped error info.
 * 6. Returns binary data if the content type is `application/octet-stream` or an image.
 * 7. Otherwise, returns the JSON response data.
 */

// Type definition for the fetcher parameters
type SWRFetcherParams = [
  url: string,
  component: string,
  queryStrings?: string[],
  auth?: string,
];

export async function swrFetcher([
  url,
  component,
  queryStrings,
  auth,
]: SWRFetcherParams): Promise<any> {
  // Check if there are query strings to be appended to the URL
  if (queryStrings && queryStrings.length > 0) {
    url += "?";
    queryStrings.forEach((query) => {
      url += "&" + query;
    });
  }

  // Initialize headers
  const headers = new Headers();
  if (auth) {
    headers.append("Authorization", "Bearer " + auth);
  }

  // Make the fetch request
  const res = await fetch(url, {
    method: "GET",
    headers,
  });

  // Redirect to sign-in if response is redirected
  if (res.ok && res.redirected) {
    posthog.reset();
    window.location.href = "/auth/sign-in";
    return;
  }

  // If response is not okay and not redirected, throw an error
  if (!res.ok && !res.redirected) {
    const prefix = format(new Date(), "yyyy/MM/dd HH:mm:ss.SSS");
    const error = new Error(
      `${prefix} - An error occurred while SWR fetches data - ${component}`
    );
    (error as any).info = await res.json();
    (error as any).status = res.status;
    throw error;
  }

  // Handle successful responses
  if (res.ok && !res.redirected) {
    const contentType = res.headers.get("Content-Type");

    // Check if response is binary (like application/octet-stream or image)
    if (
      contentType === "application/octet-stream" ||
      contentType?.startsWith("image/")
    ) {
      return { data: await res.arrayBuffer(), headers: res.headers };
    }

    // Otherwise, parse response as JSON
    return await res.json();
  }
}

/**
 * Utility function to fetch data from Next.js API routes, supporting both fetch and axios libraries.
 *
 * @param {FetchApiOptions} opt - The options for the fetch request.
 *   - tech (optional, "fetch" | "axios"): Specifies the library to use ("fetch" by default).
 *   - endpoint (string): The endpoint URL for the API request.
 *   - method ("GET" | "POST" | "PATCH"): HTTP method for the request.
 *   - endpointCallLocation (string): The component or function name initiating the call, used in error messages.
 *   - queryStrings (optional, string[]): Array of query strings to append to the URL.
 *   - body (optional, any): The request payload for POST or PATCH requests.
 *   - auth (optional, string): Bearer token for authorization, if needed.
 *   - onDownloadProgress (optional, function): Callback for monitoring download progress (axios only).
 *   - onUploadProgress (optional, function): Callback for monitoring upload progress (axios only).
 *   - isBlob (optional, boolean): If true, expects binary response data; default is false.
 *
 * @returns {Promise<any>} - A promise that resolves with the API response data, either in JSON or binary format.
 *
 * Function Workflow:
 * 1. Constructs the request URL by appending query strings, if provided.
 * 2. Builds the authorization header if an auth token is provided.
 * 3. Configures request parameters, including headers, body, and content type.
 * 4. Executes the request using either fetch or axios based on the specified tech.
 * 5. Handles potential errors:
 *    - Logs detailed error information if the request fails.
 *    - Throws a custom error with status, request configuration, and message.
 * 6. Checks the response content type:
 *    - For binary responses (e.g., application/octet-stream or images), converts the response to a Blob.
 *    - For JSON responses, parses and returns the JSON data.
 *
 * Error Handling:
 * - If the request is unsuccessful, an error is thrown with detailed information, including status and error message.
 *
 * Usage:
 * This function is designed for flexibility, supporting both JSON and binary responses, and works with both fetch and axios.
 * It can handle authentication, query strings, and provides hooks for progress tracking when using axios.
 */

// Define types for function options
type TechOption = "fetch" | "axios";
type FetchApiOptions = {
  tech?: TechOption;
  endpoint: string;
  method: "GET" | "POST" | "PATCH";
  endpointCallLocation: string;
  queryStrings?: string[];
  body?: any;
  auth?: string;
  onDownloadProgress?: (progressEvent: ProgressEvent) => void;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
  isBlob?: boolean;
};

export async function fetchApiRoute(opt: FetchApiOptions): Promise<any> {
  const {
    method,
    endpointCallLocation,
    queryStrings,
    body,
    auth,
    onDownloadProgress,
    isBlob = false,
  } = opt;
  let { tech, endpoint, onUploadProgress } = opt;

  // Set default tech to "fetch" if not specified
  if (!tech) tech = "fetch";

  // Append query strings to the URL if provided
  if (queryStrings && queryStrings.length > 0) {
    endpoint = endpoint + "?";
    queryStrings.forEach((query) => {
      endpoint = endpoint + "&" + query;
    });
  }

  // Create authorization headers if auth is provided
  let headers: Headers | Record<string, string> =
    tech === "fetch" ? new Headers() : {};
  if (auth) {
    const authHeader = "Bearer " + auth;
    if (tech === "fetch") {
      (headers as Headers).append("Authorization", authHeader);
    } else {
      headers = { Authorization: authHeader };
    }
  }

  // Build fetch request parameters
  const fetchObj: any = {
    method,
    headers,
  };

  // Add body to request if specified
  if (body) {
    const isBuffer = body instanceof ArrayBuffer;
    if (tech === "fetch") {
      (fetchObj as RequestInit).body = isBuffer ? body : JSON.stringify(body);
      (fetchObj.headers as Headers).append(
        "Content-Type",
        isBuffer ? "application/octet-stream" : "application/json"
      );
    } else {
      (fetchObj as AxiosRequestConfig).data = isBuffer
        ? body
        : JSON.stringify(body);
      (fetchObj.headers as Record<string, string>)["Content-Type"] = isBuffer
        ? "application/octet-stream"
        : "application/json";
    }
  }

  let res: any;

  // Execute request with fetch or axios based on selected tech
  if (tech === "fetch") {
    try {
      res = await fetch(endpoint, fetchObj as RequestInit);
    } catch (error: any) {
      // Handle fetch errors
      res = {
        status: error.status || 533,
        ok: false,
        data: {},
      };
      (res.data as any).config = fetchObj;
      (res.data as any).config.url = endpoint;
      (res.data as any).code = error.code;
      (res.data as any).message = error.message;
      (res.data as any).name = error.name;
      (res.data as any).detail = error.toString();
    }
  } else if (tech === "axios") {
    fetchObj.url = endpoint;
    if (isBlob) fetchObj.responseType = "blob";
    try {
      if (method === "GET" && onDownloadProgress) {
        fetchObj.onDownloadProgress = onDownloadProgress;
      }
      if ((method === "POST" || method === "PATCH") && onUploadProgress) {
        fetchObj.onUploadProgress = onUploadProgress;
      }
      res = await axios(fetchObj as AxiosRequestConfig);
    } catch (error: any) {
      // Handle axios errors
      res = {
        status: error.response?.status || 533,
        data: error.response?.data || {},
      } as AxiosResponse;
      (res.data as any).config = error.config;
      (res.data as any).request = error.request;
      (res.data as any).statusText = error.response?.statusText || "";
      (res.data as any).code = error.code;
      (res.data as any).message = error.message;
      (res.data as any).name = error.name;
    }
  }

  // Check for response success status
  const resOk =
    tech === "fetch"
      ? (res as Response).ok
      : (res as AxiosResponse).status === 200;

  // Throw an error if the response is unsuccessful
  if (!resOk) {
    const prefix = format(new Date(), "yyyy/MM/dd HH:mm:ss.SSS");
    const error = new Error(
      `${prefix} - An error occurred while front-end directly fetches data - ${endpointCallLocation}`
    );
    if (tech === "fetch") {
      try {
        (error as any).info = await (res as Response).json();
      } catch (errorCatch) {
        (error as any).info = (res as any).data;
        (error as any).status = (res as Response).status;
      }
    } else if (tech === "axios") {
      (error as any).info = (res as AxiosResponse).data;
      (error as any).status = (res as AxiosResponse).status;
    }
    if (method === "POST" || method === "PATCH") {
      (error as any).reqBody = JSON.stringify(body);
    }
    throw error;
  }

  // Determine content type for response handling
  const contentType =
    tech === "fetch"
      ? (res as Response).headers.get("Content-Type")
      : (res as AxiosResponse).headers["content-type"];

  // Handle binary responses
  if (
    contentType === "application/octet-stream" ||
    contentType?.startsWith("image/")
  ) {
    if (tech === "fetch") {
      const blob = new Blob([await (res as Response).arrayBuffer()], {
        type: "application/octet-binary",
      });
      return {
        data: blob,
        headers: (res as Response).headers,
        config: { url: endpoint },
      };
    } else {
      const convertSQLiteStringToBlob = (sqliteString: string): Blob => {
        const bytes = sqliteString
          .replace(/\\x([0-9A-Fa-f]{2})/g, (match, p1) =>
            String.fromCharCode(parseInt(p1, 16))
          )
          .split("")
          .map((c) => c.charCodeAt(0));
        return new Blob([new Uint8Array(bytes)], {
          type: "application/octet-stream",
        });
      };
      const blob = isBlob
        ? (res as AxiosResponse).data
        : convertSQLiteStringToBlob((res as AxiosResponse).data);
      return {
        data: blob,
        headers: (res as AxiosResponse).headers,
        request: (res as AxiosResponse).request,
        config: (res as AxiosResponse).config,
      };
    }
  } else {
    // Return JSON data for non-binary responses
    return tech === "fetch"
      ? (res as Response).json()
      : (res as AxiosResponse).data;
  }
}

/**
 * Analyzes the fetch response to determine the status of data loading, presence, and emptiness.
 *
 * This utility function evaluates the `data` object along with error and validation states
 * to provide indicators that simplify UI state management.
 *
 * @param data - The fetched data object.
 * @param isError - A boolean indicating if an error occurred during the fetch process.
 * @param isValidating - A boolean indicating if data is being revalidated.
 * @param source  Specifies the data source type. Can be one of:
 *   - `"backend"`: Data from a backend source.
 *   - `"gateway"`: Data from a gateway source (default).
 *   - `"auth"`: Data from an authentication source.
 * @returns An object with the following indicators:
 *   - `showLoader`: A boolean indicating if a loader should be shown (`true` for loading or validating).
 *   - `hasData`: A boolean indicating if the data exists and is non-empty.
 *   - `emptyData`: A boolean indicating if the data exists but is empty.
 */
export function renderAnalyzer(
  data: any,
  isError: boolean,
  isValidating: boolean,
  source: "backend" | "gateway" | "auth" = "gateway"
): { showLoader: boolean; hasData: boolean; emptyData: boolean } {
  // Determine if data is currently loading (no error and no data present)
  const isLoading = !isError && !data;

  // Extract the relevant data field based on the source type
  let extractData: boolean = false;

  switch (source) {
    case "backend":
      // For backend sources, ensure `data.value` is initialized to an array if it doesn't exist
      if (typeof data === "object" && data !== null && !data.value) {
        data.value = [];
      }
      extractData = data?.value ?? false;
      break;

    case "gateway":
      // For gateway sources, extract `data.data` if available
      extractData = data?.data ?? false;
      break;

    case "auth":
      // For authentication sources, use the data as is
      extractData = data ?? false;
      break;

    default:
      // If no source is specified, no specific extraction logic is applied
      break;
  }

  // Initialize flags for data presence and emptiness
  let hasData = false;
  let emptyData = false;

  // Evaluate the extracted data only if there's no error or loading
  if (!isError && !isLoading) {
    if (Array.isArray(extractData)) {
      // If data is an array, set flags based on its length
      hasData = extractData.length > 0;
      emptyData = extractData.length === 0;
    } else {
      // For non-array data, set `hasData` to true if `extractData` is truthy
      hasData = !!extractData;
    }
  }

  return {
    // Show loader if data is loading or being validated
    showLoader: isLoading || isValidating,
    // Indicates if the data exists and is non-empty
    hasData,
    // Indicates if the data exists but is empty
    emptyData,
  };
}

/**
 * Analyzes and modifies the provided data to ensure `data.value` is initialized as an array if undefined.
 *
 * @param {any} data - The data object to analyze and modify. Expected to be an object with an optional `value` property.
 * @returns {void} This function does not return a value; it modifies `data` directly if needed.
 */
export function analyzeReturnData(data: any): void {
  // If data is an object and not null, ensure `data.value` is an array if it's undefined
  if (typeof data === "object" && data !== null) {
    if (!data.value) {
      data.value = [];
    }
  }
}

/**
 * Analyzes an error object to determine if it should be reported or retried.
 *
 * @param {any} error - The error object to analyze.
 * @returns {{ reportError: boolean; retry: boolean }} An object indicating whether to report or retry the error.
 */
export function analyzeError(error: any): {
  reportError: boolean;
  retry: boolean;
} {
  let result = {
    reportError: true,
    retry: true,
  };

  // Extract additional error information if available
  const errorInfo = error.info ? error.info : undefined;

  // Specific handling for non-existent Stripe user error
  if (
    error.toString().includes("useGetPlanInfo_stripe") &&
    error.status === 400
  ) {
    result.reportError = false;
    result.retry = false;
  }

  if (errorInfo && errorInfo.endpoint && errorInfo.message) {
    const endpoint = errorInfo.endpoint;
    const message = errorInfo.message;

    // Future error filtering logic can be added here for specific endpoints or messages
    // Example:
    // const backupsEndpointRegex = /\/v1\/[a-zA-Z0-9]+\/backups/;
    // if (backupsEndpointRegex.test(endpoint)) {
    //   if (message.includes("An error occurred while looking for the node's address")) {
    //     result.reportError = false;
    //     result.retry = false;
    //   }
    // }
  }

  return result;
}

/**
 * Signs out the user and performs cleanup actions for PostHog,
 * then redirects to a specified callback URL.
 *
 * @param {string} [callbackUrl="/auth/sign-in"] - The URL to redirect the user to after sign-out.
 *   Defaults to the sign-in page.
 *
 * @returns {Promise<void>} - A promise that resolves when the sign-out process is complete.
 *
 * Function Steps:
 * 1. Fetches a CSRF token from the authentication API to ensure secure sign-out.
 * 2. Sends a POST request to the sign-out endpoint with the CSRF token.
 * 3. Resets the PostHog instance to clear any user-specific tracking data.
 * 4. Redirects the user to the specified callback URL, typically the sign-in page.
 *
 * This function is useful for securely signing out users, cleaning up any session-related data
 * from analytics (PostHog), and providing a smooth redirection experience for users post-logout.
 */
export async function signOut(
  callbackUrl: string = "/auth/sign-in"
): Promise<void> {
  try {
    // Fetch the CSRF token
    const resCsrfToken = await fetch("/api/auth/csrf");
    const csrfToken = await resCsrfToken.json();

    // Perform sign out request
    await fetch("/api/auth/signout", {
      method: "POST",
      body: JSON.stringify(csrfToken),
      headers: {
        "Content-Type": "application/json",
      },
    });

    // After successful logout, reset PostHog
    posthog.reset();

    // Redirect to sign-in page
    window.location.href = callbackUrl;
  } catch (error) {
    console.error("Sign-out error:", error);
  }
}

/**
 * Checks if sending debug emails is disabled based on environment variables.
 *
 * @returns {boolean} Returns `true` if email sending is disabled, otherwise `false`.
 */
export function disableSendMail(): boolean {
  // Check if either environment variable is set to "true"
  if (
    (process.env.NEXT_PUBLIC_UPGRADING_INFRA &&
      process.env.NEXT_PUBLIC_UPGRADING_INFRA.toLowerCase() === "true") ||
    (process.env.NEXT_PUBLIC_DISABLE_SEND_MAIL &&
      process.env.NEXT_PUBLIC_DISABLE_SEND_MAIL.toLowerCase() === "true")
  ) {
    return true; // Sending emails is disabled
  } else {
    return false; // Sending emails is enabled
  }
}

/**
 * Builds a new URL by updating the query string based on specified parameters.
 *
 * @param {string} actualPathname - The base path of the URL.
 * @param {Record<string, string>} actualQuery - The existing query parameters as key-value pairs.
 * @param {string[]} excludedQuery - An array of query keys to exclude from the URL.
 * @param {string | string[]} updateQuery - The query key(s) to add or update in the URL.
 * @param {string | string[]} newValue - The new value(s) corresponding to the `updateQuery`.
 * @returns {string} The newly constructed URL with updated query parameters.
 */
export function buildUrlFromActualQuery(
  actualPathname: string,
  actualQuery: Record<string, string>,
  excludedQuery: string[],
  updateQuery: string | string[],
  newValue: string | string[]
): string {
  // Encode newValue for safe URL usage
  const encodedNewValue = encodeURIComponent(newValue as string);

  // Initialize the new URL with the base pathname and a "?" for query parameters
  let newUrl = `${actualPathname}?`;

  // Append existing query parameters, excluding any specified in excludedQuery
  Object.entries(actualQuery).forEach(([key, value]) => {
    if (!excludedQuery.includes(key)) {
      newUrl = `${newUrl}${key}=${value}&`;
    }
  });

  // Check if updateQuery and newValue are both arrays of the same length
  if (
    Array.isArray(updateQuery) &&
    Array.isArray(newValue) &&
    updateQuery.length === newValue.length
  ) {
    // Append each query-update pair to the URL
    updateQuery.forEach((query, index) => {
      newUrl = `${newUrl}${query}=${encodeURIComponent(newValue[index])}&`;
    });
  } else {
    // Append single updateQuery and encodedNewValue if applicable
    if (updateQuery && encodedNewValue) {
      newUrl = `${newUrl}${updateQuery}=${encodedNewValue}`;
    }
  }

  // Remove any trailing "?" or "&" from the constructed URL
  newUrl = newUrl.replace(/[?&]$/, "");

  // Return the completed URL
  return newUrl;
}

/**
 * Checks if a specific class exists on an HTML element.
 *
 * @param {HTMLElement} element - The element to check for the class.
 * @param {string} className - The class name to look for.
 * @returns {boolean} Returns `true` if the class exists on the element, otherwise `false`.
 */
export function hasClass(element: HTMLElement, className: string): boolean {
  // Use classList.contains for a more reliable check
  return element.classList.contains(className);
}

/**
 * Generates a style object for table transition based on hover state.
 *
 * @param {boolean} isHovered - Indicates whether the table is in a hovered state.
 * @returns {{ opacity: number; transition: string; pointerEvents: string }} A style object with transition properties.
 */
export const tableTransition = (
  isHovered: boolean
): { opacity: number; transition: string; pointerEvents: string } => {
  return {
    opacity: isHovered ? 1 : 0,
    transition: isHovered ? "opacity 0.5s" : "opacity 0.1s",
    pointerEvents: isHovered ? "auto" : "none",
  };
};

// Backup allowed retention values
export const retentionsValue: { name: string; value: string }[] = [
  {
    name: "1 day",
    value: "24h",
  },
  {
    name: "1 week",
    value: "168h",
  },
  {
    name: "1 month",
    value: "720h",
  },
];

/**
 * Finds the name associated with a given retention value.
 *
 * @param {string} value - The retention value to look up.
 * @returns {string} The name of the retention period, or "..." if not found.
 */
export function findRetentionName(value: string): string {
  if (value) {
    const retention = retentionsValue.find(
      (retention) => retention.value === value
    );
    return retention ? retention.name : "...";
  } else {
    return "...";
  }
}

/**
 * Checks if a table name exists in the list of tables.
 *
 * @param {string} tableName - The name of the table to search for.
 * @param {Array<{ name: string }>} tablesList - The list of tables, each with a `name` property.
 * @returns {boolean} Returns `true` if the table name exists, otherwise `false`.
 */
export function tableNameExist(
  tableName: string,
  tablesList: { name: string }[]
): boolean {
  if (tablesList) {
    return tablesList.some((table) => table.name === tableName);
  }
  return false;
}

/**
 * Computes the style configuration for a Select Input component based on the specified style variant.
 *
 * @param {number} styleVariant - The variant type to determine the style.
 * @param {string | undefined} label - The label text for the input component (optional).
 * @returns {{
 *   variant: string;
 *   textFieldSx: Record<string, any>;
 *   selectSx: Record<string, any>;
 *   label?: string;
 *   className: string;
 * }} The style configuration for the Select Input.
 */
export function computeInputSelectStyle(
  styleVariant: number,
  label?: string
): {
  variant: string;
  textFieldSx: Record<string, any>;
  selectSx: Record<string, any>;
  label?: string;
  className: string;
} {
  let variant = "outlined";
  let className = "";
  let textFieldSx: Record<string, any> = {};
  let selectSx: Record<string, any> = {};

  switch (styleVariant) {
    case 0:
      variant = "outlined";
      textFieldSx = {
        width: "100%",
        mt: 2,
      };
      selectSx = {
        width: "100%",
      };
      break;
    case 1:
      variant = "outlined";
      selectSx = {
        width: "16.5rem",
      };
      break;
    case 2:
      variant = "standard";
      className = "console";
      textFieldSx = {
        minWidth: "fit-content",
        padding: "0 1rem",
        borderRadius: 0,
        borderTopLeftRadius: "8px",
        borderRight: "#5A5877 solid 1px",
      };
      selectSx = {
        boxSizing: "content-box",
        background: "black",
        color: "#F0F5FD",
        fontFamily: "Roboto Mono",
        width: "8rem",
        height: "100%",
      };
      label = undefined;
      break;
    case 3:
      variant = "outlined";
      textFieldSx = {
        width: "100%",
      };
      selectSx = {
        width: "100%",
      };
      break;
    default:
      variant = "outlined";
      selectSx = {
        width: "16.5rem",
      };
      break;
  }

  return {
    variant,
    textFieldSx,
    selectSx,
    label,
    className,
  };
}

/**
 * Converts a metadata key status number to an object containing a tooltip message and a color based on the theme.
 *
 * @param {number} status - The status code of the metadata key.
 * @param {any} theme - The theme object containing color palette information.
 * @returns {{
 *   tooltip: string;
 *   color: string;
 * }} An object representing the tooltip message and color for the metadata key status.
 */
export function convertMetadataKeyStatus(
  status: number,
  theme: any
): { tooltip: string; color: string } {
  let primaryKeyStatus: { tooltip: string; color: string };

  switch (status) {
    case 0:
      primaryKeyStatus = {
        tooltip: "",
        color: theme.palette.neutral.lightGrey,
      };
      break;
    case 1:
      primaryKeyStatus = {
        tooltip: "Primary Key.",
        color: theme.palette.success.main,
      };
      break;
    default:
      primaryKeyStatus = {
        tooltip: "",
        color: theme.palette.neutral.lightGrey,
      };
      break;
  }

  return primaryKeyStatus;
}
